const state = {
  site: {},
};

const mutations = {
  SET_SITE(state, site) {
    state.site = site;
  }
};

const getters = {
  showWatermark: (state) => state.site.showWatermark,
  site: (state) => state.site,
  currency: (state) => state.site.currency || "USD",
  integration: (state) => state.site.integration,
  isClientWhatMatters: (state) => state.site._id === 'de5b64fe-d44b-45f9-8efd-2bbd2c36784a'
};


export default {
  namespaced: true,
  state,
  getters,
  mutations,
};

